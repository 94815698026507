




























































































































































































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableActionButton from '@/@core/components/table/KTableActionButton.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import { useIndustryStore } from '../../services/industryStore';
import { useIndustryStore as newStore } from '../../services/store';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import { useAuthStore } from '@/modules/auth/services/store';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import ScaleFilter from '@/modules/industry/ui/filters/scale-filter.vue';
import IsVerifiedFilter from '../../ui/filters/is-verified-filter.vue';
import {
  toNormalDate,
  useNumberToCurrency
} from '@/@core/helpers/formatter';
import DistrictFilter from '../../ui/filters/district-filter.vue';
import IndustryName from '../../ui/table/IndustryName.vue';
import IndustryCurrencyFormat from '../../ui/table/IndustryCurrencyFormat.vue';
import IndustryScale from '../../ui/table/IndustryScale.vue';
import useTableOptions from '@/@core/helpers/table-options';
import VillageFilter from '../../ui/filters/village-filter.vue';
import { exportManyIndustry } from '../../helpers/export-to-excel';
import IsFormalFilter from '../../ui/filters/is-formal-filter.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTableWrapper,
    KTextInput,
    KTableSearch,
    KTableActionButton,
    KTableActions,
    KDialog,
    KComboboxInput,
    IsVerifiedFilter,
    DistrictFilter,
    ScaleFilter,
    IndustryName,
    IndustryCurrencyFormat,
    IndustryScale,
    VillageFilter,
    IsFormalFilter
  },
  name: 'IndustryDefaultPage',
  setup() {
    const search = ref('');
    const isLoading = ref(false);
    const selected: Ref<any> = ref(null);
    const showDeleteDialog = ref(false);
    const showVerifyDialog = ref(false);
    const showDetailDialog = ref(false);
    const industryStore = useIndustryStore();
    const store = newStore();

    const tableOptions = ref(useTableOptions());
    const headers = [
      {
        text: 'Nama Perusahaan',
        value: 'name'
      },
      // {
      //   text: 'Bentuk Usaha',
      //   value: 'businessEntity'
      // },
      // {
      //   text: 'KBLI',
      //   value: 'classification.fulltext'
      // },
      // {
      //   text: 'Biaya Perbulan',
      //   value: 'totalCostsPerMonth'
      // },
      // {
      //   text: 'Nilai Investasi',
      //   value: 'totalInvestmentValue'
      // },
      {
        text: 'Skala',
        value: 'scale',
        sortable: false
      },
      {
        text: 'Formal',
        value: 'isFormal'
      },
      {
        text: 'Diverifikasi',
        value: 'isVerified'
      },
      {
        text: 'Terdaftar',
        value: 'createdAt'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '1%'
      }
    ];

    const filter = ref({
      isFormal: 'Semua',
      isVerified: 'Semua',
      district: 'Semua',
      village: 'Semua',
      surveyMonth: '',
      surveyYear: '',
      scale: 'Semua'
    });

    const showVerifyButton = (industry: any) => {
      const role = useAuthStore().role;
      const isAdmin = role == 'ADMIN';
      const isIndustryVerified = industry.isVerified;

      return isAdmin && !isIndustryVerified;
    };

    const deleteIndustry = (industry: any) => {
      selected.value = industry;

      showDeleteDialog.value = true;
    };

    const detailIndustry = (industry: any) => {
      selected.value = industry;

      showDetailDialog.value = true;
    };

    const verifyIndustry = (industry: any) => {
      selected.value = industry;

      showVerifyDialog.value = true;
    };

    const getManyIndustry = async () => {
      isLoading.value = true;

      await store.getManyIndustry({
        filter,
        options: tableOptions,
        search
      });

      isLoading.value = false;
    };

    const onVerifyIndustry = async () => {
      isLoading.value = true;

      await store.verifyIndustry(selected.value);

      useUiStateStore().createSnackbar({
        message: `Industri ${selected.value.name} berhasil diverifikasi.`
      });

      await getManyIndustry();
      showVerifyDialog.value = false;
      isLoading.value = false;
    };

    const onDeleteIndustry = async () => {
      isLoading.value = true;

      await store.deleteIndustry(selected.value);

      useUiStateStore().createSnackbar({
        message: `Industri ${selected.value.name} berhasil dihapus.`
      });

      await getManyIndustry();
      showDeleteDialog.value = false;
      isLoading.value = false;
    };

    const exportToExcel = async () => {
      // // @ts-ignore
      // const tempLimit = unref(tableOptions).limit;
      // // @ts-ignore
      // tableOptions.value.limit = -1;
      await exportManyIndustry(store.industries);
      // // @ts-ignore
      // tableOptions.value.limit = tempLimit;
    };

    const isAdmin = computed(() => useAuthStore().role == 'ADMIN');

    watch(filter, async (v) => await getManyIndustry(), {
      immediate: false,
      deep: true
    });

    watch(tableOptions, async (v) => await getManyIndustry(), {
      immediate: false,
      deep: false
    });

    watch(search, async (v) => {
      const isFirstPage = tableOptions.value.page == 1;
      if (isFirstPage) return await getManyIndustry();

      tableOptions.value.page = 1;
    });

    const authStore = useAuthStore();
    const isEmployee = computed(() => authStore.role == 'EMPLOYEE');

    onMounted(() => {
      if (unref(isEmployee))
        filter.value.district = authStore.user?.district?.name;
    });

    return {
      isMobile,
      search,
      industryStore,
      headers,
      showDeleteDialog,
      showVerifyDialog,
      showDetailDialog,
      verifyIndustry,
      deleteIndustry,
      detailIndustry,
      onDeleteIndustry,
      isLoading,
      selected,
      store,
      showVerifyButton,
      onVerifyIndustry,
      filter,
      useNumberToCurrency,
      isAdmin,
      tableOptions,
      toNormalDate,
      exportToExcel,
      isEmployee
    };
  }
});
