














import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KComboboxInput },
  name: 'IndustryIsVerifiedFilter',
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });

    const isFormalList = [
      {
        text: 'Semua',
        value: 'Semua'
      },
      {
        text: 'Formal',
        value: 'Formal'
      },
      {
        text: 'Non Formal',
        value: 'Non Formal'
      }
    ];

    return {
      modelValue,
      isFormalList
    };
  }
});
