import { defineStore } from "pinia"
import { reactive, toRefs } from "@vue/composition-api"
import { useSanitizeString } from "@/@core/helpers/sanitizeString"
import { useClassificationStore } from "@/modules/classification/services/store"
import { TableOptions } from "@/@core/helpers/table-options"

type IndustryState = {
    industry: null | any
    industries: any[]
}

export const useIndustryStore = defineStore('industryz', () => {
    const state = reactive<IndustryState>({
        industry: null,
        industries: []
    })

    const createIndustry = async (industry: any) => {
        const timestamp = new Date().valueOf()
        const id = useSanitizeString(String(timestamp))

        const key = 'kbli'
        await useClassificationStore().getManyClassification({} as TableOptions)
        const classifications = useClassificationStore().classifications as unknown as any[]
        const classification = classifications.find((c: any) => c.fulltext == industry[key])
        industry[key] = classification
    }

    const getManyIndustry = async () => {
        const industries = localStorage.getItem(
            'industries',
        )

        if (industries) state.industries = JSON.parse(industries)
    }

    const getOneIndustry = async (id: string) => {
        await getManyIndustry()
        const industry = state.industries.find(i => i.id === id)
        if (industry) state.industry = industry
    }

    const deleteIndustry = async (id: string) => {
        await getManyIndustry()
        const industries = state.industries
        const industry = industries.find(i => i.id === id)
        if (industry) {
            state.industries = industries.filter(i => i.id !== id)
            localStorage.setItem(
                'industries',
                JSON.stringify(state.industries)
            )
        }
    }

    return {
        ...toRefs(state),
        createIndustry,
        getManyIndustry,
        getOneIndustry,
        deleteIndustry
    }
})
    