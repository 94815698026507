import { currencyToNumber } from '@/@core/helpers/formatter';
import toPhoneNumber from '@/modules/landing/helpers/to-phone-number';
import toWhatsAppNumber from '@/modules/landing/helpers/to-whatsapp-number';
import { unref } from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import { IndustryForm } from '../types';

const contactsToText = (contacs: any) => {
  let text: string = '';

  for (const key in contacs) {
    let prefix = '';
    let value = contacs[key];
    if (key == 'instagram') prefix = '@';
    if (key == 'whatsapp') {
      prefix = '+';
      value = toWhatsAppNumber(value);
    }

    text += `${key}: ${prefix}${value}, \n`;
  }

  return text;
};

const employeesToText = (employees: any) => {
  return `Laki-laki: ${employees.male ?? '-'}\n, Perempuan: ${
    employees.female ?? '-'
  }`;
};

const getBusinessPermitType = (permissions: any[]) => {
  return permissions.map((p: any) => p.type).join(', ');
};

const getBusinessPermitYear = (permissions: any[]) => {
  const years = permissions
    .map((p: any) =>
      p.date ? new Date(p.date).toISOString().slice(0, 10) : null
    )
    .join(', ');

  return years ? "'" + years : null;
};

const getBusinessPermitNumber = (permissions: any[]) => {
  const filtered = permissions.filter((p) => p.value);
  return filtered.map((p: any) => `${p.type}: ${p.value}`).join(', ');
};

const getClassificationCode = (classification: any) =>
  classification?.code;
const getClassificationTitle = (classification: any) =>
  classification?.title;

const getMaleEmployee = (employees: any) => employees?.male;
const getFemaleEmployee = (employees: any) => employees?.female;

const getTotalProduction = (products: any[]) =>
  products.reduce((p, c) => p + c.capacityPerYear, 0);

const getTotalProductionValue = (products: any[]) => {
  const total = products.reduce(
    (p, c) =>
      currencyToNumber(p) + currencyToNumber(c.productionValue),
    0
  );

  const divideBy = 1000;
  const formatedTotal = String(total / divideBy).replace(/\./g, ',');

  return formatedTotal;
};

const getMaterialCost = (materials: any[]) => {
  if (!materials?.length) return null;

  const rawMaterials = materials.find(
    (m) => m.value === 'raw-materials'
  );
  const additivesMaterials = materials.find(
    (m) => m.value === 'additives-materials'
  );
  const auxiliaryMaterials = materials.find(
    (m) => m.value === 'auxiliary-materials'
  );

  const getCost = (materialData: any[]) =>
    materialData.reduce(
      (cost: any, m: any) =>
        cost + currencyToNumber(m.totalNeedsPerYear),
      0
    ) as number;

  const rawMaterialCost = getCost(rawMaterials.data);
  const additivesMaterialCost = getCost(additivesMaterials.data);
  const auxiliaryMaterialCost = getCost(auxiliaryMaterials.data);

  return (
    (rawMaterialCost +
      additivesMaterialCost +
      auxiliaryMaterialCost) /
    1000
  );
};

const getInvestmentValue = (investmentValues: any[]) => {
  if (!investmentValues?.length) return null;

  const getInvestment = (type: string) =>
    investmentValues.find((i) => i.value === type);
  const equipmentInvest = getInvestment('equipments');
  const facilityInvest = getInvestment('facilities');

  const getInvestmentTotal = (data: any[]) =>
    data.reduce(
      (total, d) => total + currencyToNumber(d?.acquisitionValue),
      0
    );

  const equipmentInvestTotal = getInvestmentTotal(
    equipmentInvest.data
  );
  const facilityInvestTotal = getInvestmentTotal(facilityInvest.data);

  return (equipmentInvestTotal + facilityInvestTotal) / 1000;
};

const getContact = (contacts: any, type: string) =>
  contacts ? contacts[type] : null;

const industryToExportData = (industry: IndustryForm) => {
  const i = industry;

  const dataOriginal = {
    // name: i.name ?? '-',
    // address: i.address,
    // geolocation: i?.geolocation?.latitude
    //   ? `${i.geolocation.latitude}, ${i.geolocation.longitude}`
    //   : '-',
    // npwp: i.npwp ?? '-',
    // inChargeName: i.inChargeName ?? '-',
    // ownerName: i.ownerName ?? '-',
    // businessEntity: i.businessEntity ?? '-',
    // phoneNumber: i.phoneNumber
    //   ? '`+' + toWhatsAppNumber(i.phoneNumber)
    //   : '-',
    // classification: i?.classification?.fulltext || '-',
    // contacts: contactsToText(i.contacts) ?? '-',
    // employees: employeesToText(i.employees) ?? '-',
    // totalInvestmentValue: i.totalInvestmentValue ?? '-',
    // // @ts-ignore
    // totalCostsPerMonth: i.totalCostsPerMonth ?? '-',
    // district: i.district ?? '-',
    // village: i.village ?? '-'
  };

  const toNumberString = (v: any) => `'${v}`;

  const getValue = <T>(value: T) => {
    if (!value) return '';
    if (typeof value === 'number') return `'${value}`;
    return value;
  };

  const getDataYear = () =>
    i?.surveyedAt && new Date(i.surveyedAt).getFullYear();

  const data = {
    name: getValue(i.name),
    ownerName: getValue(i.ownerName),
    latitude: getValue(toNumberString(i?.geolocation?.latitude)),
    longitude: getValue(toNumberString(i?.geolocation?.longitude)),
    address: getValue(i.address),
    village: getValue(i.village),
    district: getValue(i.district),
    city: 'Banjarmasin',
    province: 'Kalimantan Selatan',
    contactPerson: '-',
    phoneNumber: getValue(toPhoneNumber(i.phoneNumber)),
    email: getValue(getContact(i.contacts, 'email')),
    website: getValue(getContact(i.contacts, 'website')),
    businessPermit: getValue(getBusinessPermitType(i.permissions)),
    businessPermitNumber: getValue(
      getBusinessPermitNumber(i.permissions)
    ),
    businessPermitYear: getValue(
      getBusinessPermitYear(i.permissions)
    ),
    businessEntity: getValue(i.businessEntity),
    classificationCode: getValue(
      getClassificationCode(i.classification)
    ),
    classificationName: getValue(
      getClassificationTitle(i.classification)
    ),
    products: getValue(i.tags?.join(', ')),
    // dataYear: '2022',
    dataYear: getDataYear(),
    maleEmployees: getValue(getMaleEmployee(i.employees)),
    femaleEmployees: getValue(getFemaleEmployee(i.employees)),
    investmentValue: getValue(
      getInvestmentValue((i as any).investmentValues)
    ),
    qty: getValue(getTotalProduction(i.products)),
    unit: '-',
    productionValue: getValue(getTotalProductionValue(i.products)),
    materialValue: getValue(getMaterialCost(i.materials)),
    exportOrientation: 'Belum',
    exportDestinationCountries: '-',
    isActive: 'Aktif',
    isNonActive: '-',
    financing: getValue(i.finance?.name)
  };

  return data;
};

type DataKey = keyof ReturnType<typeof industryToExportData>;

const exportManyIndustry = async (industries: any[]) => {
  return new Promise((resolve, reject) => {
    const _industries: any[] = unref(industries);

    const mapsOriginal = {
      // name: 'Nama Perusahaan',
      // address: 'Alamat Perusahaan',
      // geolocation: 'Titik Koordinat',
      // npwp: 'NPWP',
      // inChargeName: 'Penanggung Jawab',
      // ownerName: 'Pemilik',
      // businessEntity: 'Bentuk Usaha',
      // phoneNumber: 'No. Telp',
      // classification: 'KBLI',
      // permissions: 'Izin',
      // contacts: 'Kontak',
      // employees: 'Tenaga Kerja',
      // totalInvestmentValue: 'Nilai Investasi',
      // totalCostsPerMonth: 'Biaya Bahan Baku',
      // products: 'Produk',
      // district: 'Kecamatan',
      // village: 'Kelurahan'
    };

    const maps: Record<DataKey, string> = {
      name: 'Nama Perusahaan',
      ownerName: 'Nama Pemilik',
      latitude: 'Latitude',
      longitude: 'Longitude',
      address: 'Jalan',
      village: 'Desa/Kel',
      district: 'Kec',
      city: 'Kab/Kota', //
      province: 'Provinsi', //
      contactPerson: 'Kontak Person',
      phoneNumber: 'Telepon/HP',
      email: 'Email', //
      website: 'Website', //
      businessPermit: 'Izin Usaha Industri', //
      businessPermitNumber: 'Nomor Izin Usaha', //
      businessPermitYear: 'Tahun Dikeluarkan Izin', //
      businessEntity: 'Badan Usaha', // konversi
      classificationCode: 'KBLI 2020', //
      classificationName: 'Jenis Industri', //
      products: 'Produk', //
      dataYear: 'Tahun Data', //
      maleEmployees: 'Tenaga Kerja (L)', //
      femaleEmployees: 'Tenaga Kerja (P)', //
      investmentValue:
        'Nilai Investasi (tidak termasuk tanah dan bangunan) (Rp. 000)',
      qty: 'Jumlah Produksi/tahun', // ?
      unit: 'Satuan', // ?
      productionValue: 'Nilai produksi (Rp. 000)', //
      materialValue: 'Nilai BB/BP (Rp. 000)', //
      exportOrientation: 'Orientasi Ekspor', //
      exportDestinationCountries: 'Negara Tujuan Ekspor', //
      isActive: 'Aktif',
      isNonActive: 'Tidak',
      financing: 'Akses Pembiayaan'

      // geolocation: 'Titik Koordinat',
      // npwp: 'NPWP',
      // inChargeName: 'Penanggung Jawab',
      // permissions: 'Izin',
      // contacts: 'Kontak',
      // employees: 'Tenaga Kerja',
      // totalInvestmentValue: 'Nilai Investasi',
      // totalCostsPerMonth: 'Biaya Bahan Baku',
    };

    const headers = [
      {
        text: 'Nama Perusahaan',
        value: 'name'
      },
      {
        text: 'Alamat Perusahaan',
        value: 'address'
      },
      {
        text: 'Titik Koordinat',
        value: 'geolocation'
      },
      {
        text: 'NPWP',
        value: 'npwp'
      },
      {
        text: 'Penanggung Jawab',
        value: 'inChargeName'
      },
      {
        text: 'Pemilik',
        value: 'ownerName'
      },
      {
        text: 'Bentuk Usaha',
        value: 'businessEntity'
      },
      {
        text: 'No. Telp',
        value: 'phoneNumber'
      },
      {
        text: 'KBLI',
        value: 'classification'
      },
      {
        text: 'Izin',
        value: 'permissions'
      },
      {
        text: 'Kontak',
        value: 'contacts'
      },
      {
        text: 'Tenaga Kerja',
        value: 'employees'
      },
      {
        text: 'Nilai Investasi',
        value: 'totalInvestmentValue'
      },
      {
        text: 'Biaya Bahan Baku',
        value: 'totalCostsPerMonth'
      },
      {
        text: 'Produk',
        value: 'products'
      },
      {
        text: 'Kecamatan',
        value: 'district'
      },
      {
        text: 'Kelurahan',
        value: 'village'
      }
    ];

    const data = _industries.map((i) => industryToExportData(i));

    // const data = _industries
    const fileName = 'download';
    const exportType = exportFromJSON.types.xls;

    // console.log({ _industries });
    // return;

    resolve(
      exportFromJSON({ data, fileName, exportType, fields: maps })
    );
  });
};

export { exportManyIndustry };
