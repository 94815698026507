function insert(str: string, index: number, value: string) {
  return str.substr(0, index) + value + str.substring(index);
}

export default function toPhoneNumber(v: string) {
  if (!v) return null;
  let value = v;

  if (value.startsWith('62')) v = '0' + value.slice(2);
  if (value.startsWith('+62')) v = '0' + value.slice(3);

  // let formattedValue = value.replace(/.{4}/g, '$&' + '-');
  // if (formattedValue.endsWith('-'))
  //   formattedValue = formattedValue.slice(0, -1);

  if (value?.length < 10) return null;
  let formattedValue = insert(value, 8, '-');
  formattedValue = insert(formattedValue, 4, '-');

  return formattedValue;
}
