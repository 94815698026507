






import KText from '@/@core/components/typography/KText.vue';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: { KText },
  props: {
    value: {
      type: String as PropType<string>,
      required: false
    }
  },
  name: 'TableColumnScale',
  setup() {
    const scaleColor = (scale: string) => {
      let color = 'grey lighten-3';

      if (scale == 'mikro') color = 'grey lighten-3';
      if (scale == 'kecil') color = 'success';
      if (scale == 'menengah') color = 'info';

      return color;
    };

    return {
      scaleColor
    };
  }
});
